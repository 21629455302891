import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {
  EuiSpacer,
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from '@elastic/eui'

export default function ImageWall() {

  return (
    <React.Fragment>
      <EuiFlexItem>
        <EuiFlexGroup gutterSize="s" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign='left'
              paddingSize='l'
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/Multiview/ImageWall/InstarVision_Android_Multiview_01.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Camera View'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        The Multiview Menu can be accessed by the Cameras button and is opened by default when you start the application.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
            <EuiCard
              textAlign='left'
              paddingSize='m'
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/Multiview/ImageWall/InstarVision_Android_Multiview_02.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Pause Alarms'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                        The Bell icon on the top pauses the alarm from all cameras. You can also use the Bell icon above a specific camera to only pause or un-pause the alarm for this camera.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Multiview/ImageWall/InstarVision_Android_Multiview_03.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Multi Selection'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Click on the Three-Dots icon on top to activate a mode that allows you to select multiple cameras...
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Multiview/ImageWall/InstarVision_Android_Multiview_04.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Multi Selection'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Once selected you will see a new arrow shaped button on top that allows you to send a command to all selected cameras.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Multiview/ImageWall/InstarVision_Android_Multiview_05.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Multi Selection'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Click on the Arrow button to open the command menu and select the command you want to send.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Multiview/ImageWall/InstarVision_Android_Multiview_06.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Multi Selection'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Once in selection mode clicking on the Three-Dots icon allows you to select or de-select all cameras and to cancel the selection mode.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>

        <EuiSpacer />

        <EuiFlexItem>
          <EuiFlexGroup gutterSize="l" direction="row" >
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Multiview/ImageWall/InstarVision_Android_Multiview_07.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Push Notification'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          The Phone icon above a camera enables the Push Notification setup. This enables a push notification from your camera to your mobile device every time an alarm is triggered. 
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Multiview/ImageWall/InstarVision_Android_Multiview_08.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Reorder your Cameras'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          The Grid icon on the top activates a mode that allows you to drag&drop your cameras to rearrange the view.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiCard
                textAlign="left"
                image={
                  <StaticImage
                    src='../../../../../images/Software/Android/InstarVision/Multiview/ImageWall/InstarVision_Android_Multiview_09.webp'
                    alt='Add your camera using its P2P address'
                    placeholder="dominantColor"
                    formats={["auto", "webp", "avif"]}
                    loading="lazy"
                  />
                }
                title='Camera Control'
                description={
                  <EuiFlexGroup justifyContent="flexEnd">
                      <EuiFlexItem grow={false}>
                        <EuiText>
                          Use the small Right Arrow button on the camera preview image to access the camera control menu. This menu gives you access to most of your cameras features usually controlled through the webUI.
                        </EuiText>
                        <EuiSpacer size="s" />
                      </EuiFlexItem>
                  </EuiFlexGroup>
                }
              />
            </EuiFlexItem>
          </EuiFlexGroup>
      </EuiFlexItem>

      <EuiSpacer />

      <EuiFlexItem>
        <EuiFlexGroup gutterSize="l" direction="row" >
          <EuiFlexItem>
            <EuiCard
              textAlign="left"
              image={
                <StaticImage
                  src='../../../../../images/Software/Android/InstarVision/Multiview/ImageWall/InstarVision_Android_Multiview_10.webp'
                  alt='Add your camera using its P2P address'
                  placeholder="dominantColor"
                  formats={["auto", "webp", "avif"]}
                  loading="lazy"
                />
              }
              title='Camera Control'
              description={
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                      <EuiText>
                          Use the small Right Arrow button on the camera preview image to access the camera control menu. This menu gives you access to most of your cameras features usually controlled through the webUI.
                      </EuiText>
                      <EuiSpacer size="s" />
                    </EuiFlexItem>
                </EuiFlexGroup>
              }
            />
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
          <EuiFlexItem>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
    </React.Fragment>
  );
}