import React from 'react'

import {
  EuiSpacer,
  EuiTitle,
  EuiText
} from '@elastic/eui'

import ImageWall from '../../../../../components/Software/Android/InstarVision/ImageWalls/instarvision-android-cameras'
import FloatingGallery from '../../../../../components/Software/Android/InstarVision/Galleries/instarvision-android-multiview-floating-gallery'

import MultimediaCards from "../../../../../components/Software/Android/InstarVision/instarVisionMultimediaCards"

import NavButtons from '../../../../../components/Software/Android/InstarVision/NavButtons'

import SEOHelmet from '../../../../../components/Layout/SEOHelmet'
import BreadCrumbs from '../../../../../components/Layout/BreadCrumbs'


const seodata = {
  title: 'Android App InstarVision',
  description: 'The Android app InstarVision for your Android tablet or smart phone. Control your camera when you don´t have access to your computer.',
  image: '/images/Search/P_SearchThumb_InstarVision_Android.png',
  twitter: '/images/Search/P_SearchThumb_InstarVision_Android.webp',
  locationEN: '/en/Software/Android/InstarVision/Multiview/',
  locationDE:'/de/Software/Android/InstarVision/Multiview/',
  locationFR:'/fr/Software/Android/InstarVision/Multiview/'
}

function InstarVisionMultimedia(props) {
  return (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        twitter={seodata.twitter}
        location={props.location} />

      <BreadCrumbs
    locationBC={props.location}
          locationEN={seodata.locationEN}
          locationDE={seodata.locationDE}
          locationFR={seodata.locationFR}
          crumbLabel="Multiview" />
          
      <NavButtons />
    
      <EuiTitle size="l">
        <h1>InstarVision Surveillance Center</h1>
      </EuiTitle>
      <EuiTitle size="m">
        <h2>Android Software</h2>
      </EuiTitle>
      <EuiTitle size="s">
        <h4>The Multiview Menu allows you to keep an eye on all your cameras</h4>
      </EuiTitle>
      <EuiSpacer size="l" />
      <EuiText>
        <p>Every camera that you add to the app will be displayed in the Multiview Menu. The Video will be displayed with a low refresh rate to keep bandwidth requirements low. Simply touch the video to access the corresponding cameras live stream.</p>
      </EuiText>
      <EuiSpacer size="xl" />
      <FloatingGallery />
      <EuiSpacer size="xl" />
      <MultimediaCards />
      <EuiSpacer size="l" />
      <ImageWall />
    </React.Fragment>
  );
}

export default InstarVisionMultimedia